import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import {
	ApiDelete,
	ApiGetNoAuth,
	ApiPost,
	ApiPut,
	Bucket,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";

export default function States() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [totalPage, setTotalPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [searching, setSearching] = useState("");
	const [formModal, setFormModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [newData, setNewData] = useState({
		stateName: "",
		abbreviation: ""
	});

	const columns = [
		{
			dataField: "_id",
			text: "ID",
			formatter: (cell, row, i) => {
				return <div className="d-flex align-items-center">{(currentPage - 1) * pageSize + i + 1 || "-"}</div>;
			},
		},
		{
			dataField: "stateName",
			text: "State Name",
			sort: true,
			formatter: (cell, row) => {
				return (
					<div>{cell}</div>
				);
			},
		},
		{
			dataField: "abbreviation",
			text: "abbreviation",
			sort: true,
			formatter: (cell, row) => {
				return (
					<div>{cell}</div>
				);
			},
		},
		{
			dataField: "createdAt",
			text: "createdAt",
			sort: true,
			formatter: (cell, row) => {
				return <div>{moment(cell).format("DD-MM-YYYY") || "-"}</div>;
			},
		},
		{
			dataField: "action",
			text: "Action",
			sort: true,
			formatter: (cell, row) => {
				return (
					<>
						<a
							title="Edit customer"
							className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
							onClick={() => editBtnClick(row)}
						>
							<span className="svg-icon svg-icon-md svg-icon-primary">
								<SVG
									src={toAbsoluteUrl(
										"/media/svg/icons/Communication/Write.svg"
									)}
								/>
							</span>
						</a>
						<a
							title="Delete customer"
							className="btn btn-icon btn-light btn-hover-danger btn-sm"
							onClick={() => {
								Swal.fire({
									text: `Are you sure to Delete State ?`,
									icon: "warning",
									showCancelButton: true,
									showConfirmButton: true,
									confirmButtonText: `Yes, Delete`,
									confirmButtonColor: "#D72852",
									cancelButtonColor: "transparent",
									cancelButtonText: "No, Cancel",
								}).then((res) => {
									if (res.isConfirmed) {
										handleDelete(row?._id)
									}
								});
							}}
						>
							<span className="svg-icon svg-icon-md svg-icon-danger">
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
								/>
							</span>
						</a>
					</>
				);
			},
		},
	];

	const handleChange = (event) => {
		const { value, name } = event.target;
		setNewData({ ...newData, [name]: value })
	}
	console.log("newData", newData);
	const addBtnClick = () => {
		setIsEdit(false);
		setNewData({ stateName: "", abbreviation: "" })
		setFormModal(true);
	}
	const editBtnClick = (rowData) => {
		setIsEdit(true);
		setFormModal(true)
		setNewData({
			id: rowData?._id,
			stateName: rowData?.stateName,
			abbreviation: rowData?.abbreviation
		})
	}
	const onPaginationChange = (e, i) => {
		setCurrentPage(i)
		fetchData(i, pageSize, searching)

	}

	const handleOnChangesPagination = (e) => {
		setCurrentPage(1)
		setPageSize(e.target.value);
		fetchData(1, Number(e.target.value), searching)
	}

	const handleSearch = (event) => {
		setSearching(event.target.value)
		fetchData(currentPage, pageSize, event.target.value)
	}

	const handleSubmit = async () => {
		setLoading(true);
		let body = {
			stateName: newData?.stateName,
			abbreviation: newData?.abbreviation
		}
		await ApiPost("admin/add/state", body)
			.then(() => {
				SuccessToast("State Added Successfully")
				setFormModal(false);
				setLoading(false);
				fetchData(currentPage, pageSize, searching);
			})
			.catch(err => {
				ErrorToast(err?.message);
				setLoading(false);
			})
	}
	const handleUpdate = async () => {
		let body = {
			id: newData?.id,
			stateName: newData?.stateName,
			abbreviation: newData?.abbreviation
		}
		await ApiPut("admin/update/state", body)
			.then(() => {
				SuccessToast("State Updated Successfully")
				setFormModal(false);
				fetchData(currentPage, pageSize, searching);
			})
			.catch(err => {
				ErrorToast(err?.message)
			})
	}
	const handleDelete = async (rowId) => {
		console.log("rowId", rowId);
		await ApiDelete("admin/delete/state/" + rowId)
			.then(() => {
				SuccessToast("State Deleted Successfully");
				fetchData(currentPage, pageSize, searching)
			})
			.catch(err => {
				ErrorToast(err?.message)
			})
	}
	const fetchData = async (page, limit, search) => {
		let body = { page, limit, search };
		await ApiPost("admin/get/state", body)
			.then((res) => {
				console.log("admin/get/state", res?.data?.data);
				setData(res?.data?.data);
				setTotalPage(res?.data?.state?.page_limit);
			})
			.catch((err) => ErrorToast(err?.message));
	};
	useEffect(() => {
		fetchData(currentPage, pageSize, searching);
	}, []);

	return (
		<>
			<div className="d-flex justify-content-between mb-4">
				<div className="title">
					<div className="fs-20px fw-bolder">State List</div>
					<div>
						<span
							role="button"
							onClick={() => history.push("/dashboard")}
							className="text-hover-info text-muted"
						>
							Home
						</span>{" "}
						-{" "}
						<span className="text-muted" role="button">
							{" "}
							State
						</span>
					</div>
				</div>
			</div>
			<div className="d-flex flex-column flex-column-fluid h-100" id="kt_content">
				<div className="card card-custom">
					<div className="card-header flex-wrap border-0 pt-6 pb-0 w-100">
						<div className="card-title ">
							<div className="input-icon">
								<input
									type="text"
									className="form-control bg-light"
									name="searchText"
									placeholder="Search by Name"
									value={searching}
									onChange={handleSearch}
								/>
								<span>
									<i className="flaticon2-search-1 text-muted"></i>
								</span>
							</div>
						</div>

						<div className="card-toolbar">
							<a
								className="btn btn-color font-weight-bolder btn-sm ms-4"
								onClick={addBtnClick}
							>
								Add State
							</a>
						</div>
					</div>
					<div className="card-body mb-5">
						<BootstrapTable
							wrapperClasses="table-responsive"
							headerWrapperClasses="border-0"
							bordered={false}
							classes="table table-head-custom table-vertical-center overflow-hidden"
							bootstrap4
							keyField="_id"
							data={data || []}
							columns={columns}
							noDataIndication={() => <NoDataTable />}
						/>
						<div className="d-flex justify-content-between pt-10">
							<div className="my-2">
								<Pagination
									count={totalPage}
									page={currentPage}
									onChange={onPaginationChange}
									variant="outlined"
									shape="rounded"
									className="pagination_"
								/>
							</div>
							<div className="my-2">
								<div className="d-flex align-items-center">
									<select
										className="form-select"
										onChange={handleOnChangesPagination}
										value={pageSize}
									>
										<option value={10}>10</option>
										<option value={20}>20</option>
										<option value={30}>30</option>
										<option value={50}>50</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={formModal} size="lg" onHide={() => setFormModal(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title>{isEdit?"Update state":"Add  State"}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<Form.Group>
							<Form.Label>State Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter State Name"
								title="Enter State Name"
								label="stateName"
								id="stateName"
								name="stateName"
								onChange={handleChange}
								value={newData?.stateName}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Abbreviation</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Abbreviation"
								title="Enter Abbreviation"
								label="abbreviation"
								id="abbreviation"
								name="abbreviation"
								onChange={handleChange}
								value={newData?.abbreviation}
							/>
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{isEdit ? (
						<button className="btn btn-color"
							onClick={handleUpdate}
							disabled={loading}
						>
							{loading ? "Loading" : "Update Changes"}
						</button>
					) : (
						<button className="btn btn-color"
							onClick={handleSubmit}
							disabled={loading}
						>
							{
								loading
									? "Loading" : "Submit"}
						</button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}
