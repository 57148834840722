/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const signOut = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  let userInfo = JSON.parse(localStorage.getItem("userinfo"));

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        {userInfo?.response?.userType === 1 && (
          <>
            <li
              className={`menu-item ${getMenuItemActive("/states", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/states">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Map/Direction1.svg")}
                  />
                </span>
                <span className="menu-text">States</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/quotes", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/quotes">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Code/Question-circle.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Quotes</span>
              </NavLink>
            </li>

            <li
              className={`menu-item ${getMenuItemActive(
                "/splitapplied",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/splitapplied">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-right-panel-2.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Split Applied</span>
              </NavLink>
            </li>

            <li
              className={`menu-item ${getMenuItemActive("/category", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/category">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")}
                  />
                </span>
                <span className="menu-text">Category</span>
              </NavLink>
            </li>

            <li
              className={`menu-item ${getMenuItemActive("/blog", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/blog">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Blogs</span>
              </NavLink>
            </li>
          </>
        )}
        {/* <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/error",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/error">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Earth.svg")} />
              </span>
              <span className="menu-text">Manage Categories</span>
              <i className="menu-arrow mt-2" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Manage Categories</span>
                  </span>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/category_List")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/category_List">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text"> Main Categories</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/subCategory_List"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/subCategory_List">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text"> Sub Categories</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/software_List")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/software_List">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text"> Software</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li> */}
        <li
          className={`menu-item ${getMenuItemActive("", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={() => signOut()}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")}
              />
            </span>
            <span className="menu-text">Sign Out</span>
          </div>
        </li>
      </ul>
    </>
  );
}
