import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
// import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import { MdCancel } from "react-icons/md";
// import { Editor } from "react-rte";
import RichTextEditor from "react-rte";

export default function Blogs() {
  const date = new Date();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searching, setSearching] = useState("");
  const [category, setCategory] = useState([]);
  const [richValue, setRichValue] = React.useState(
    RichTextEditor.createEmptyValue()
  );
  const [pipData, setPipData] = React.useState({});
  // const onChanges = (value) => {
  //   setrichValue(value);
  //   const htmlContent = value.toString("html");
  //   let modifiedHtml = htmlContent.replace(/<\/?strong>/g, ""); // Remove <strong> tags
  //   modifiedHtml = modifiedHtml.replace(
  //     /<h1>(.*?)<\/h1>/g,
  //     (match, group) =>
  //       `<h1 id='${group
  //         .trim()
  //         .replace(
  //           /\s/g,
  //           "_"
  //         )}' class='text-3xl font-bold pt-4 mb-2 font-judson_italic'>${group}</h1>`
  //   );
  //   modifiedHtml = modifiedHtml.replace(
  //     /<h2>(.*?)<\/h2>/g,
  //     (match, group) =>
  //       `<h2 id='${group
  //         .trim()
  //         .replace(
  //           /\s/g,
  //           "_"
  //         )}' class='text-2xl font-bold pt-4 mb-2 font-judson_italic'>${group}</h2>`
  //   );

  //   setpipData({ ...pipData, description: modifiedHtml });
  // };

  const onChanges = (value) => {
    setRichValue(value);
    const htmlContent = value.toString("html");
    let modifiedHtml = htmlContent.replace(/<\/?strong>/g, ""); // Remove <strong> tags

    // Remove <br> tags only if they are not within <h1> tags
    modifiedHtml = modifiedHtml.replace(/<br>(?![^<]*<\/h1>)/g, "");

    modifiedHtml = modifiedHtml.replace(/<h1>(.*?)<\/h1>/g, (match, group) => {
      // Check if the <h1> tag starts with a line break or spaces
      if (/^(<br>|\s*ENTER|\s+)/.test(group)) {
        return `<p>${group.replace(/^(<br>|\s*ENTER|\s+)/, "")}</p>`;
      } else {
        const id = group.trim().replace(/\s/g, "_");
        return `<h1 id='${id}' class='text-3xl font-bold pt-4 mb-2 font-judson_italic'>${group}</h1>`;
      }
    });
    modifiedHtml = modifiedHtml.replace(
      /<h2>(.*?)<\/h2>/g,
      (match, group) =>
        `<h2 id='${group
          .trim()
          .replace(
            /\s/g,
            "_"
          )}' class='text-2xl font-bold pt-4 mb-2 font-judson_italic'>${group}</h2>`
    );

    // Trim leading spaces from each sentence
    modifiedHtml = modifiedHtml.replace(
      /<p>(\s*)(.*?)(<\/p>)/g,
      (match, startSpaces, sentence, endTag) => {
        const trimmedSentence = sentence.trimStart();
        return `<p>${startSpaces}${trimmedSentence}${endTag}`;
      }
    );

    setPipData({ ...pipData, description: modifiedHtml });
  };

  const [newData, setNewData] = useState({
    image: "",
    title: "",
    author: "",
    description: "",
    categoryId: "",
  });
  const columns = [
    {
      dataField: "_id",
      text: "ID",

      formatter: (cell, row, i) => {
        return (
          <div className="d-flex align-items-center text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {(currentPage - 1) * pageSize + i + 1 || "-"}
          </div>
        );
      },
    },
    {
      dataField: "image",
      text: "Image",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="cursor-pointer">
            <img
              src={
                row?.picture
                  ? `${Bucket}${row?.picture}`
                  : "https://static.vecteezy.com/system/resources/thumbnails/014/066/812/small/discount-ribbon-banner-icon-for-graphic-design-logo-website-social-media-mobile-app-ui-illustration-vector.jpg"
              }
              alt=""
              width={50}
              height={50}
              className="rounded object-cover"
            />
          </div>
        );
      },
    },
    {
      dataField: "Title",
      text: "Title",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {row?.title ? row?.title : "-"}
          </div>
        );
      },
    },
    {
      dataField: "author",
      text: "Author",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {row?.author ? row?.author : "-"}
          </div>
        );
      },
    },
    {
      dataField: "categoryName",
      text: "category",
      sort: true,
      formatter: (cell, row) => {
        console.log("categoryrow", row);
        return (
          <div className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {row?.categoryName ? row?.categoryName : "-"}
          </div>
        );
      },
    },
    {
      dataField: "shortDescription",
      text: "Description",
      sort: true,
      formatter: (cell, row) => {
        const descriptionStyle = {
          width: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer",
        };
        return (
          <div
            style={descriptionStyle}
            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
          >
            {row?.shortDescription || "-"}
          </div>
        );
      },
    },
    {
      dataField: "Description",
      text: "Description",
      sort: true,
      formatter: (cell, row) => {
        const descriptionStyle = {
          width: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer",
        };
        const descriptionText = document.createElement("div");
        descriptionText.innerHTML = row?.body ? row?.body : "-";
        const textContent = descriptionText.innerText;

        return (
          <div
            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
            style={descriptionStyle}
          >
            {textContent}
          </div>
        );
      },
    },
    {
      dataField: "topTick",
      text: "Top Blogs",
      sort: true,
      style: { width: "100px" },
      formatter: (cell, row) => {
        console.log("rowtoppik", row);
        if (row?.topTick == true) {
          return (
            <span
              className="label font-weight-bold label-lg label-light-success label-inline"
              role="button"
              onClick={() => {
                console.log("row", row);
                Swal.fire({
                  text: `Are you sure you want remove this post from first 3 position ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes`,
                  confirmButtonColor: "#D72852",
                  cancelButtonColor: "white",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    ApiGet(`admin/toptick/${row?._id}`)
                      .then(() => fetchData(currentPage, pageSize, searching))
                      .catch((err) => ErrorToast(err?.message));
                  }
                });
              }}
            >
              Top Rated
            </span>
          );
        }
        if (row?.topTick == false) {
          return (
            <span
              className="label font-weight-bold label-lg label-light-danger label-inline"
              role="button"
              onClick={() => {
                console.log("row", row);
                Swal.fire({
                  text: `Are you sure you want see this post in first 3 position  ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes`,
                  confirmButtonColor: "#D72852",
                  cancelButtonColor: "white",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    ApiGet(`admin/toptick/${row?._id}`)
                      .then(() => fetchData(currentPage, pageSize, searching))
                      .catch((err) => ErrorToast(err?.message));
                  }
                });
              }}
            >
              Unrated
            </span>
          );
        } else {
          return <span className=""> - </span>;
        }
      },
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {moment(cell).format("MM-DD-YYYY") || "-"}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex">
              <a
                title="Edit customer"
                className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
                onClick={() => editBtnClick(row)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
              <a
                title="Delete customer"
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => {
                  Swal.fire({
                    text: `Are you sure you want to Delete ?`,
                    icon: "warning",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: `Yes, Delete`,
                    confirmButtonColor: "#D72852",
                    cancelButtonColor: "white",
                    cancelButtonText: "No, Cancel",
                  }).then((res) => {
                    if (res.isConfirmed) {
                      onDelete(row?._id);
                    }
                  });
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
            </div>
          </>
        );
      },
    },
  ];

  const handleChange = (e) => {
    const { value, name } = e.target;
    const trimmedValue = value.trimStart(); // Remove spaces at the beginning
    setNewData({ ...newData, [name]: trimmedValue });
  };

  const editBtnClick = (row) => {
    console.log("row", row);
    setIsEdit(true);
    setModal(true);
    setNewData({
      id: row?._id,
      image: row?.picture,
      title: row?.title,
      author: row?.author,
      description: row?.shortDescription,
      categoryId: row?.categoryId,
    });
    setRichValue(RichTextEditor.createValueFromString(row?.body, "html"));
  };
  const onDelete = async (Id) => {
    await ApiDelete(`admin/delete/blog/${Id}`)
      .then(() => {
        Swal.fire({
          text: "You have Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
        fetchData(currentPage, pageSize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const onUpdate = async () => {
    if (!newData?.image) {
      return ErrorToast("Image is Required!");
    }
    if (!newData?.title || newData?.title?.trim()?.length === 0) {
      return ErrorToast("Title is Required!");
    }
    if (!newData?.author || newData?.author?.trim()?.length === 0) {
      return ErrorToast("Author is Required!");
    }
    if (!newData?.categoryId || newData?.categoryId?.trim?.().length === 0) {
      return ErrorToast("category is Required!");
    }
    if (!newData?.description || newData?.description?.trim?.().length === 0) {
      return ErrorToast("Description is Required!");
    }
    if (
      newData?.image &&
      newData?.title?.trim()?.length > 0 &&
      newData?.author?.trim()?.length > 0 &&
      newData?.description?.trim?.().length > 0 &&
      newData?.categoryId?.trim?.().length > 0
    ) {
      let body = {
        id: newData?.id,
        picture: newData?.image,
        title: newData?.title,
        author: newData?.author,
        body: pipData?.description,
        shortDescription: newData?.description,
        categoryId: newData?.categoryId,
      };
      console.log("body", body);
      await ApiPut("admin/update/blog", body)
        .then((res) => {
          SuccessToast(res?.message);
          setModal(false);
          fetchData(currentPage, pageSize, searching);
        })
        .catch((err) => ErrorToast(err?.message));
    }
  };

  const handleSubmit = async () => {
    if (!newData?.image) {
      return ErrorToast("Image is Required!");
    }
    if (!newData?.title || newData?.title?.trim?.().length === 0) {
      return ErrorToast("Title is Required!");
    }
    if (!newData?.author || newData?.author?.trim?.().length === 0) {
      return ErrorToast("Author is Required!");
    }
    if (!newData?.categoryId || newData?.categoryId?.trim?.().length === 0) {
      return ErrorToast("category is Required!");
    }
    if (!newData?.description || newData?.description?.trim?.().length === 0) {
      return ErrorToast("Description is Required!");
    }
    if (!pipData?.description && pipData?.description !== "") {
      return ErrorToast("Blog is Required!");
    }
    let body = {
      picture: newData?.image,
      title: newData?.title,
      body: pipData?.description,
      author: newData?.author,
      shortDescription: newData?.description,
      categoryId: newData?.categoryId,
    };

    if (
      newData?.image &&
      newData?.title?.trim?.().length > 0 &&
      newData?.author?.trim?.().length > 0 &&
      newData?.description?.trim?.().length > 0 &&
      newData?.categoryId?.trim?.().length > 0 &&
      (pipData?.description || pipData?.description === "")
    ) {
      await ApiPost("admin/add/blog", body)
        .then((res) => {
          console.log("res?.data?.message", res?.data);

          SuccessToast(res?.message);
          setModal(false);
          fetchData(currentPage, pageSize, searching);
        })
        .catch((err) => ErrorToast(err?.message));
    }
  };

  const handleonchnagespagination = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
    fetchData(1, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    setCurrentPage(i);
    fetchData(i, pageSize, searching);
  };
  const imageChange = async (e) => {
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);
    await ApiUpload("upload/image", formData)
      .then((res) => {
        console.log("res", res);
        setNewData({ ...newData, image: res?.data });
      })
      .catch((err) => console.log("res_blob", err));
  };
  const handlesearch = (e) => {
    setCurrentPage(1);
    setSearching(e.target.value);
    fetchData(1, pageSize, e.target.value);
  };

  const getCategory = async () => {
    await ApiGet("admin/get/category")
      .then((res) => {
        setCategory(res?.data);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const fetchData = async (page, limit, search) => {
    let body = { page, limit, search };
    await ApiPost("admin/get/blog", body)
      .then((res) => {
        setData(res?.data?.getBlog);
        setTotalPage(res?.data?.state?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData(currentPage, pageSize, searching);
    getCategory();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Blog List</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Blog
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title ">
              <div class="input-icon">
                <input
                  type="text"
                  class="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Title"
                  value={searching}
                  onChange={(e) => handlesearch(e)}
                  id="kt_datatable_search_query"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div class="card-toolbar">
              <a
                class="btn btn1 btn-color  font-weight-bolder btn-sm ms-4"
                onClick={() => {
                  setIsEdit(false);
                  setModal(true);
                  setNewData({
                    image: "",
                    title: "",
                    author: "",
                    description: "",
                    categoryId: "",
                  });

                  setRichValue(RichTextEditor.createEmptyValue());
                }}
              >
                Add Blogs
              </a>
            </div>
          </div>
          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={data || []}
              columns={columns}
              noDataIndication={() => <NoDataTable />}
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalPage}
                  page={currentPage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div class="my-2">
                <div class="d-flex align-items-center pagination-drpdown">
                  <select
                    class="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pageSize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modal} onHide={() => setModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {!isEdit ? " Add Blog" : "Blog Edit Details"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form.Group className="col-md-12">
              <Form.Label>Image</Form.Label>
              <input
                type="file"
                className="form-control"
                name="image"
                id="image"
                onChange={imageChange}
              />
              <span className="errorInput"></span>
            </Form.Group>
            {newData?.image && (
              <div className="position-relative w-auto">
                <img
                  src={newData?.image}
                  alt="sd"
                  style={{ width: "100px", height: "100px" }}
                  className="rounded-3"
                />
                <span
                  className="imageRemovoIconSpan"
                  onClick={() => {
                    setNewData({ ...newData, image: "" });
                    document.getElementById("image").value = null;
                  }}
                >
                  <MdCancel color="red" fontSize={20} />
                </span>
              </div>
            )}
            <Form.Group className="col-md-12">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                label="title"
                id="title"
                required
                name="title"
                onChange={handleChange}
                value={newData.title}
              />
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Author</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Author"
                label="author"
                id="author"
                required
                name="author"
                onChange={handleChange}
                value={newData.author}
              />
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Categories</Form.Label>
              <Form.Control
                as="select"
                custom
                id="country"
                required
                className="form-control"
                name="categoryId"
                onChange={handleChange}
                value={newData?.categoryId}
              >
                <option value="">Select Category</option>
                {category?.map((v, i) => {
                  console.log("v", v);
                  return (
                    <>
                      <option value={v?._id} key={v?._id}>{v?.categoryName}</option>
                    </>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Description"
                label="description"
                id="description"
                required
                name="description"
                onChange={handleChange}
                value={newData.description}
                style={{ whiteSpace: "pre-wrap" }}
              />
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Blog</Form.Label>

              <RichTextEditor
                value={richValue}
                onChange={onChanges}
                placeholder="Enter Some Description"
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isEdit ? (
            <button className="btn btn1 btn-color" onClick={onUpdate}>
              Update Changes
            </button>
          ) : (
            <button className="btn btn1 btn-color" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={viewModal}
        onHide={() => setViewModal(false)}
        centered
        className="modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Blog Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="text-center" style={{ marginBottom: "1.75rem" }}>
              <img
                src={
                  newData?.image ||
                  "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png";
                }}
                alt="userImage"
                className=" rounded-pill object-cover"
                height="100px"
                width="100px"
              />
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Title </div>
              <div className="col-md-7 text-capitalize">
                {newData?.title ?? "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">Author </div>
              <div className="col-md-7">{newData?.author ?? "-"}</div>
            </div>

            <div className="row py-2 text-break">
              <div className="col-md-5 fw-bold ">Blog Data </div>
              <div className="col-md-7">{pipData ?? "-"}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-color" onClick={() => setViewModal(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
