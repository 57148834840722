import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { ApiGet } from "../../helpers/API/ApiData"
import { ErrorToast } from "../../helpers/Toast"

export function DashboardPage() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const fetchData = async () => {
    await ApiGet("admin/count")
      .then(res => setData(res?.data))
      .catch(err => ErrorToast(err?.message))
  }
  useEffect(() => {
    fetchData();
  }, [])
  let userInfo= JSON.parse(localStorage.getItem("userinfo"))
  return <div className="row">
    <div className="col-lg-12 col-xxl-12">
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Dashboard</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Dashboard
            </span>
          </div>
        </div>
      </div>

      <div className="row">


        <div className="col-12 col-xxl-5 col-xl-6 col-lg-8 col-md-10 col-sm-12">
          <div className="card">
            <div className="card-body">
              <h2>Explain Life Statistics</h2>
              <div className="row gy-5 gx-10 mt-1">
                <div className="col-sm-6">
                  <div className={`card py-3 rounded-20px ${userInfo?.response?.userType === 1&&"cursor-pointer"}`} style={{ backgroundColor: "#CDEFFF" }}
                  onClick={() => {
                   if (userInfo?.response?.userType === 1) {
                     history.push("/quotes");
                   }
                 }}
                 >
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Quotes</div>
                          <div className="h1">
                            {data?.usages || 0}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#D6F7D7" }} onClick={() => { }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Summary</div>
                          <div className="h1">
                            {data?.summary || 0}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className={`card py-3 rounded-20px ${userInfo?.response?.userType === 1&&"cursor-pointer"}`} style={{ backgroundColor: "#EFECFF" }}
                    onClick={() => {
                      if (userInfo?.response?.userType === 1) {
                        history.push("/states");
                      }
                    }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>State</div>
                          <div className="h1">
                            {data?.state || 0}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Electric/Washer.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#FFD8E5" }} onClick={() => { }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Subscriber</div>
                          <div className="h1">
                            {data?.subscriber || 0}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      
    </div>
  </div>
}
