import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import { ApiGetNoAuth, ApiPost, Bucket } from "../../../helpers/API/ApiData";
import { ErrorToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { formatCurrency } from "../../../helpers/utils";
import { AiFillCaretRight, AiFillCaretDown } from "react-icons/ai";

export default function Quotes() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [expandData, setExpandData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searching, setSearching] = useState("");

  const columns = [
    {
      dataField: "data",
      text: "ID",
      formatter: (cell, row, i) => {
        return (
          <div className="d-flex align-items-center">
            {(currentPage - 1) * pageSize + i + 1 || "-"}
          </div>
        );
      },
    },
    {
      dataField: "data",
      text: "face Amount",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>{cell?.length ? (formatCurrency(cell[0]?.quotes?.face_amounts[0] || 0)) : 0}</div>
        );
      },
    },
    {
      dataField: "data",
      text: "gender",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell?.length ? cell[0]?.quotes?.gender : "-"}</div>;
      },
    },
    {
      dataField: "data",
      text: "birthdate",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>{cell?.length ? moment(cell[0]?.quotes?.birthdate).format("MM-DD-YYYY") : "-"}</div>
        );
      },
    },
    {
      dataField: "data",
      text: "smoker",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell?.length ? cell[0]?.quotes?.smoker : "-"}</div>;
      },
    },
    {
      dataField: "data",
      text: "state",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell?.length ? cell[0]?.quotes?.state : "-"}</div>;
      },
    },
    {
      dataField: "data",
      text: "createdAt",
      sort: true,
      formatter: (cell, row) => {
        console.log("rowasd", cell[0]?.quotes);
        return <div>{cell?.length ? moment(cell[0]?.createdAt).format("MM-DD-YYYY") : "-"}</div>;
      },
    },
  ];
  const expandColumns = [
    {
      dataField: "quotes",
      text: "ID",
      formatter: (cell, row, i) => {
        return (
          <div className="d-flex align-items-center">
            {(currentPage - 1) * pageSize + i + 1 || "-"}
          </div>
        );
      },
    },
    {
      dataField: "quotes",
      text: "face Amount",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {formatCurrency(cell?.face_amounts ? cell?.face_amounts[0] : 0)}
          </div>
        );
      },
    },
    {
      dataField: "quotes",
      text: "gender",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell?.gender}</div>;
      },
    },
    {
      dataField: "quotes",
      text: "birthdate",
      sort: true,
      formatter: (cell, row) => {
        return <div>{moment(cell?.birthdate).format("MM-DD-YYYY")}</div>;
      },
    },
    {
      dataField: "quotes",
      text: "smoker",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell?.smoker}</div>;
      },
    },
    {
      dataField: "quotes",
      text: "state",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell?.state}</div>;
      },
    },
    {
      dataField: "createdAt",
      text: "createdAt",
      sort: true,
      formatter: (cell, row) => {
        return <div>{moment(cell).format("MM-DD-YYYY")}</div>;
      },
    },
  ];
  const expandRow = {
    showExpandColumn: true,
    renderer: (row) => {
      console.log("expandRow", row);
      const expData = expandData?.find((val) => {
        if (val?._id === row?._id) {
          return val;
        }
      });
      return (
        <div className="ms-15">
          <BootstrapTable
            wrapperClasses="table-responsive"
            headerWrapperClasses="border-0"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            keyField="_id"
            data={expData?.data || []}
            columns={expandColumns}
            noDataIndication={() => <NoDataTable />}
          />
        </div>
      );
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <AiFillCaretDown className="cursor-pointer" fontSize={20} />;
      }
      return <AiFillCaretRight className="cursor-pointer" fontSize={20} />;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <AiFillCaretDown className="cursor-pointer" fontSize={20} />;
      }
      return <AiFillCaretRight className="cursor-pointer" fontSize={20} />;
    },
    parentClassName: "bg-light",
    expandByColumnOnly: true,
  };

  const onPaginationChange = (e, i) => {
    setCurrentPage(i);
    fetchData(i, pageSize, searching);
  };
  const handleOnChangesPagination = (e) => {
    setCurrentPage(1);
    setPageSize(e.target.value);
    fetchData(1, Number(e.target.value), searching);
  };

  const handleSearch = (event) => {
    setCurrentPage(1);
    setSearching(event.target.value);
    fetchData(1, pageSize, event.target.value);
  };

  const fetchData = async (page, limit, search) => {
    let body = { page, limit, search };
    await ApiPost("admin/usages", body)
      .then((res) => {
        console.log("admin/usages", res?.data?.quotes);
        let dataTable = [];
        let a = res?.data?.quotes?.length ? res?.data?.quotes?.map((v, i) => {
          let data = v?.data?.length ? v?.data?.filter((val, index) => {
            if (index !== 0) {
              return val;
            }
          }) : [];
          dataTable.push({ ...v, data });
        }) : [];
        setData(res?.data?.quotes);
        setExpandData(dataTable);
        setTotalPage(res?.data?.state?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData(currentPage, pageSize, searching);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Quotes List</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Quotes
            </span>
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-column flex-column-fluid h-100"
        id="kt_content"
      >
        <div className="card card-custom">
          <div className="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div className="card-title ">
              <div className="input-icon">
                <input
                  type="text"
                  className="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Name"
                  value={searching}
                  onChange={handleSearch}
                />
                <span>
                  <i className="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            {/* <div className="card-toolbar">
							<a
								className="btn btn-color font-weight-bolder btn-sm ms-4"

							>
								Add State
							</a>
						</div> */}
          </div>
          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={data || []}
              columns={columns}
              expandRow={expandRow}
              noDataIndication={() => <NoDataTable />}
            />
            <div className="d-flex justify-content-between pt-10">
              <div className="my-2">
                <Pagination
                  count={totalPage}
                  page={currentPage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <select
                    className="form-select"
                    onChange={handleOnChangesPagination}
                    value={pageSize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
