export const isObjectEmpty = object => {
  return Object.keys(object).length === 0;
};

export const isArrayEmpty = array => {
  return array.length === 0;
};

export const formatCurrency = (value) => (
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(value)
);

export const formatCurrencyWithDecimals = (value) => (
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  }).format(value)
);

export const formatPercentWithDecimals = (value) => (
  new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(value)
)

export const formatPercent = (value) => (
  new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 0,
  }).format(value)
)

export const cleanNumber = (numStr) => {
  return Number(numStr.replace(/[^0-9.]/g, ""));
};

export const cleanPercent = (rateStr) => {
  return Number(rateStr.replace(/[^0-9.]/g, "")) / 100;
};
