import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./Dashboard/DashboardPage";
import States from "./pages/States/States";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Quotes from "./pages/Usages/Quotes";
import Split from "./pages/Split Applied/Split";
import Blogs from "./pages/Blog/Blogs";
import Category from "./pages/Category/Category";
let userInfo = JSON.parse(localStorage.getItem("userinfo"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />

        {userInfo?.response?.userType === 1 && (
          <>
            <ContentRoute path="/states" component={States} />
            <ContentRoute path="/quotes" component={Quotes} />
            <ContentRoute path="/splitapplied" component={Split} />
            <ContentRoute path="/blog" component={Blogs} />
            <ContentRoute path="/Category" component={Category} />
          </>
        )}

        <ContentRoute path="/error/error-v1" component={ErrorsPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
